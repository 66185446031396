import * as Sentry from '@sentry/vue';
import type { Plugin } from 'vue';
import type { Router } from 'vue-router';

export interface SentryPluginOptions {
  router: Router;
  tags: Record<string, string>;
}

export const sentry = {
  install(app, options: SentryPluginOptions) {
    if (!import.meta.env.VITE_SENTRY_DSN) return;

    Sentry.init({
      app,
      debug: !!import.meta.env.VITE_SENTRY_DEBUG,
      logErrors: !!import.meta.env.DEV,
      dsn: import.meta.env.VITE_SENTRY_DSN,
      integrations: [
        Sentry.browserTracingIntegration({
          router: options.router,
        }),
        Sentry.replayIntegration(),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
      replaysSessionSampleRate: 0.5,
      replaysOnErrorSampleRate: 1.0,
      enabled: true,
      release: import.meta.env.VITE_SENTRY_RELEASE || 'unknown',
      environment:
        import.meta.env.VITE_SENTRY_ENVIRONMENT || import.meta.env.MODE,
    });

    Sentry.setTags(options.tags);
  },
} satisfies Plugin;

export default sentry;
