import memoize from 'fast-memoize';
import { LRUCache } from 'lru-cache';

// biome-ignore lint/suspicious/noExplicitAny: import eslint
export default <T extends (...args: any[]) => any>(
  fn: T,
  options: LRUCache.Options<string, ReturnType<T>, unknown> = {
    max: 100,
  },
): T =>
  memoize(fn, {
    cache: {
      create: () => {
        const cache = new LRUCache<string, ReturnType<T>>(options);

        return {
          has: (key) => {
            return cache.has(key);
          },

          get: (key) => {
            return cache.get(key)!;
          },

          set: (key, value) => {
            if (
              typeof value === 'object' &&
              (value as object) instanceof Promise
            ) {
              const valueAsPromise = value as Promise<Awaited<ReturnType<T>>>;

              cache.set(key, valueAsPromise as ReturnType<T>);

              valueAsPromise.catch(() => {
                cache.delete(key);
              });
            } else {
              cache.set(key, value);
            }
          },
        };
      },
    },
  });
