/* eslint-disable @typescript-eslint/no-use-before-define */
import type { FieldKeyValueItem } from './choice';

export type FieldChoiceValue = {
  key: string;
  value: string;
};

export type FieldChoiceValueInput = string | FieldChoiceValue;

/**
 * Convert field choice value input to the value
 * that should be puts in the taxonomy template output.
 */
export function formatFieldChoiceValue(options: {
  /** Accepts `TaxonomyFieldChoice` */
  field: {
    type: 'choice';
    parameters: {
      keyValues: FieldKeyValueItem[];
      freeValueIfNoChoice?: boolean;
      freeValue?: boolean;
    };
  };
  input: FieldChoiceValueInput | undefined;
}): string {
  const { field, input } = options;

  const keyValue = asKeyValue(input, field.parameters.keyValues);
  const value = asString(input, field.parameters.keyValues);

  if (!keyValue && value && field.parameters.freeValue) return value;

  return keyValue ? keyValue.value : '';
}

/**
 * Convert field choice value input to the value
 * structured output object.
 */
export function decodeFieldChoiceValue(options: {
  /** Accepts `TaxonomyFieldChoice` */
  field: { type: 'choice'; parameters: { keyValues: FieldKeyValueItem[] } };
  input: FieldChoiceValueInput | undefined;
}): FieldChoiceValue | undefined {
  const { field, input } = options;

  const keyValue = asKeyValue(input, field.parameters.keyValues);

  if (!keyValue) return undefined;

  return {
    value: keyValue.value,
    key: keyValue.key,
  };
}

function asKeyValue(
  input: FieldChoiceValueInput | undefined,
  keyValues: FieldKeyValueItem[],
): FieldKeyValueItem | undefined {
  if (typeof input === 'string') {
    return keyValues.find(({ value }) => value === input);
  } else if (typeof input === 'object' && input.key) {
    return keyValues.find(({ value }) => value === input.key);
  }

  return undefined;
}

function asString(
  input: FieldChoiceValueInput | undefined,
  keyValues: FieldKeyValueItem[],
): string | undefined {
  if (typeof input === 'string') {
    return input;
  } else if (typeof input === 'object' && input.key) {
    return keyValues.find(({ value }) => value === input.key)?.value;
  }

  return undefined;
}
