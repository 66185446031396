import {
  addBreadcrumb,
  captureEvent,
  captureException,
  captureMessage,
  getIsolationScope,
  setTag,
  setTags,
  setUser,
  startInactiveSpan,
  startSpan,
} from '@sentry/core';

const sentry = {
  addBreadcrumb,
  getIsolationScope,
  startSpan,
  startInactiveSpan,
  setUser,
  setTag,
  setTags,
  captureException,
  captureEvent,
  captureMessage,
};

export {
  addBreadcrumb,
  getIsolationScope,
  startSpan,
  startInactiveSpan,
  setUser,
  setTag,
  setTags,
  captureException,
  captureEvent,
  captureMessage,
};

export default sentry;
